import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="dragdrop"
export default class extends Controller {
  static targets = ["draggable", "droppable"]

  connect() {
    this.draggableTargets.forEach((e) => e.classList.add("draggable"))
    this.currentDraggable = null
  }

  start(event) {
    if (event.target.dataset.dragdropTarget == "draggable") {
      this.currentDraggable = event.target
    }
  }

  enter(event) {
    if (this.isDroppable(event.target)) {
      event.preventDefault()
      event.target.classList.add("alert")
    }
  }

  over(event) {
    if (this.isDroppable(event.target)) {
      event.preventDefault()
    }
  }

  leave(event) {
    if (this.isDroppable(event.target)) {
      event.target.classList.remove("alert")
    }
  }

  drop(event) {
    if (this.currentDraggable) {
      event.target.classList.remove("alert")
      const url = this.currentDraggable.dataset.url
      const method = this.currentDraggable.dataset.method
      const params = event.target.dataset.params
      this.ajaxCall(url, method, params)
    }
  }

  end(event) {
    this.currentDraggable = null
  }

  isDroppable(target) {
    return (
      target.dataset.dragdropTarget == "droppable" &&
      this.currentDraggable
    )
  }

  async ajaxCall(url, method, params) {
    const request = new FetchRequest(method, url, {
      body: params,
      contentType: "application/json",
      responseKind: "turbo-stream"
    })
    const response = await request.perform()
  }
}
