// Generated by CoffeeScript 2.7.0
var CopyToClipboard;

CopyToClipboard = class CopyToClipboard {
  constructor(el) {
    this.copy = this.copy.bind(this);
    this.el = el;
    this.input = this.el.find("input.copy");
    this.button = this.el.find("input.perform-copy");
    this.el.on('submit', this.copy);
  }

  copy(event) {
    var element, originalSelectionEnd, originalSelectionStart;
    event.preventDefault();
    element = this.input[0];
    originalSelectionStart = element.selectionStart;
    originalSelectionEnd = element.selectionEnd;
    element.focus();
    element.setSelectionRange(0, element.value.length);
    document.execCommand("copy");
    element.setSelectionRange(originalSelectionStart, originalSelectionEnd);
    return false;
  }

};

$(function() {
  return $("form.copy-to-clipboard").each(function(index, element) {
    return new CopyToClipboard($(element));
  });
});
