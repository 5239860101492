// Generated by CoffeeScript 2.7.0
var AdjustableQuantity;

AdjustableQuantity = class AdjustableQuantity {
  constructor(el) {
    this.recalculate = this.recalculate.bind(this);
    this.el = el;
    this.orderItemId = this.el.data('order-item-id');
    this.confirmedQuantity = this.el.data('confirmed-quantity');
    this.radioButtons = $(`.adjustable-quantity[data-order-item-id=${this.orderItemId}] input[type=radio]`);
    this.radioButtons.change(this.recalculate);
    this.recalculate();
  }

  recalculate() {
    var currentValue;
    currentValue = this.confirmedQuantity;
    this.radioButtons.each(function(index, element) {
      var radioButton;
      radioButton = $(element);
      if ((radioButton.data('adjust-quantity') != null) && radioButton.is(':checked')) {
        return currentValue += radioButton.data('adjust-quantity');
      }
    });
    return this.el.text(currentValue);
  }

};

$(function() {
  return $('span.adjustable-quantity').each(function(index, element) {
    return new AdjustableQuantity($(element));
  });
});
