const $ = require("jquery");
window.$ = $;
window.jQuery = $;

require("jquery-ujs");
require("jquery-ui/dist/jquery-ui");
require("../../vendor/assets/javascripts/jquery-ui-timepicker-addon");
select2 = require("select2");
select2();
require("select2/dist/js/i18n/de");
require("@nathanvda/cocoon");
require("../../vendor/assets/javascripts/datatables/jquery.dataTables");
import "chartkick/chart.js"

require("./action_dropdown");
require("./address");
require("./address_autocomplete");
require("./address_dialog");
require("./nested_forms");
require("./trainer_description");
require("./payments");
require("./dialog_forms");
require("./hotel_book");
require("./alternative_trainers");
require("./assign_room");
require("./order_address_row");
require("./adjustable_quantity");
require("./copy_to_clipboard");
require("./issues");
require("./dependent_select");
require("./mass_event");
require("./check_column");
require("./week_loader");
require("./reservations");
require("./form_options");
require("./field_toggle");
require("./course_date_show");

import "./controllers"
import "@hotwired/turbo-rails"

Turbo.session.drive = false

$(function() {
  var futureDateSelects, minDate;
  $("#navigation li").mouseenter(function() {
    $(".sub_navigation").hide();
    return $(`#${$(this).attr('id')}-sub`).show();
  });
  $("#year_switch").change(function() {
    return window.location = window.location.pathname + '?year=' + $(this).val();
  });
  $.datepicker.setDefaults({
    dateFormat: 'yy-mm-dd',
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    firstDay: 1,
    monthNames: ['Januar', 'Februar', 'Marz', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    showWeek: true,
    weekHeader: 'KW'
  });
  $.timepicker.setDefaults({
    timeOnlyTitle: 'Zeit Waehlen',
    timeText: 'Zeit',
    hourText: 'Stunde',
    minuteText: 'Minute',
    secondText: 'Sekunde',
    millisecText: 'Millisekunde',
    timezoneText: 'Zeitzone',
    currentText: 'Jetzt',
    closeText: 'Fertig',
    timeFormat: 'hh:mm z',
    ampm: false
  });
  $("input.date-select").datepicker();
  futureDateSelects = $("input.future-date-select");
  if (futureDateSelects.length > 0) {
    minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    futureDateSelects.datepicker({
      minDate: minDate
    });
  }
  $("input.datetime-select").datetimepicker();
  $('button.toggle-all').click(function(event) {
    var check_boxes;
    event.preventDefault();
    check_boxes = $(this).closest('form').find('input[type=checkbox]');
    return check_boxes.prop('checked', !check_boxes.prop('checked'));
  });

  $("input[data-reload-on-click]").click(function(e) {
    $(this).closest('form').submit();
  });

  return $('a[data-merge-address]').click(function() {
    return $('.choose-merge-address').toggle();
  });


});
