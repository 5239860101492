// Generated by CoffeeScript 2.7.0
var AssignRoom;

AssignRoom = class AssignRoom {
  constructor(el) {
    this.openDialog = this.openDialog.bind(this);
    this.el = el;
    this.el.click(this.openDialog);
  }

  openDialog(event) {
    event.preventDefault();
    return $.get(this.el.data('url'), function(data) {
      var dialogHtml;
      dialogHtml = $(`<div>${data}</div>`);
      dialogHtml.find('select.room').select2({
        templateResult: function(option) {
          if (!option.id) {
            return option.text;
          }
          return $($(option.element).data('html'));
        }
      });
      return $(dialogHtml).dialog({
        autoOpen: true,
        title: "Zimmer zuweisen",
        minWidth: 800
      });
    });
  }

};

$(function() {
  return $('a.assign-room').each(function(index, element) {
    return new AssignRoom($(element));
  });
});
