// Generated by CoffeeScript 2.7.0
window.initAddressAutocomplete = function(select) {
  var url;
  url = select.data('url');
  return select.select2({
    ajax: {
      url: url,
      dataType: 'json',
      delay: 250,
      data: function(params) {
        return {
          q: params.term
        };
      },
      processResults: function(data, params) {
        return {
          results: data.map(function(element) {
            return {
              id: element.id,
              text: element.search_string
            };
          })
        };
      }
    },
    placeholder: "Keine Adresse ausgewählt (N.N.)",
    minimumInputLength: 3,
    allowClear: true,
    language: 'de'
  });
};

$(function() {
  return $("select.address-autocomplete").each(function(index, element) {
    return window.initAddressAutocomplete($(this));
  });
});
