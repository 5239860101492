// Generated by CoffeeScript 2.7.0
var DialogForm;

DialogForm = class DialogForm {
  constructor(el) {
    this.open = this.open.bind(this);
    this.el = el;
    this.el.dialog({
      autoOpen: location.hash.substring(1) === this.el.attr('id'),
      width: 800
    });
    $(`.open-dialog-form[data-target=${this.el.attr('id')}]`).click(this.open);
  }

  open(event) {
    event.preventDefault();
    this.el.dialog('open');
    return this.el.find("select.address-autocomplete").each(function(index, element) {
      return window.initAddressAutocomplete($(this));
    });
  }

};

$(function() {
  return $(".dialog-form").each(function(index, element) {
    return new DialogForm($(element));
  });
});
