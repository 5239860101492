// Generated by CoffeeScript 2.7.0
var FormOptions;

FormOptions = class FormOptions {
  constructor(el) {
    this.selectOption = this.selectOption.bind(this);
    this.el = el;
    this.el.find(".form-option").click(this.selectOption);
  }

  selectOption(event) {
    var option;
    this.el.find(".form-option").each(function(index, option) {
      option = $(option);
      option.find("input[type=hidden]").prop("disabled", true);
      return option.removeClass("form-option-selected");
    });
    option = $(event.target);
    option.find("input[type=hidden]").prop("disabled", false);
    return option.addClass("form-option-selected");
  }

};

$(function() {
  return $('[data-form-options]').each(function(index, element) {
    return new FormOptions($(element));
  });
});
