import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dependent-selects"
export default class extends Controller {
  static targets = ["baseSelect", "dependentSelect", "optionsTemplate"]
  static values = {idAttribute: String}

  connect() {
    this.update()
  }

  update() {
    const selectedId = this.baseSelectTarget.value
    const options = this.optionsTemplateTarget.content.querySelectorAll("option")
    this.dependentSelectTarget.replaceChildren()
    for (const option of options) {
      if (option.dataset[this.idAttributeValue] == selectedId) {
        this.dependentSelectTarget.append(option.cloneNode(true))
      }
    }
  }
}
