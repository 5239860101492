import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="popup"
export default class extends Controller {
  static targets = ["window"]

  connect() {
  }

  open(event) {
    event.preventDefault()
    this.windowTarget.classList.remove("hide")
  }

  close(event) {
    event.preventDefault()
    this.windowTarget.classList.add("hide")
  }

  toggle(event) {
    event.preventDefault()
    this.windowTarget.classList.toggle("hide")
  }
}
