// Generated by CoffeeScript 2.7.0
var OrderAddressRow;

OrderAddressRow = class OrderAddressRow {
  constructor(el) {
    this.toggleOn = this.toggleOn.bind(this);
    this.toggleOff = this.toggleOff.bind(this);
    this.el = el;
    this.id = this.el.data('address-id');
    if (this.id !== '') {
      this.el.on('mouseenter', this.toggleOn);
      this.el.on('mouseleave', this.toggleOff);
    }
  }

  toggleOn() {
    return $(`table.order tr[data-address-id=${this.id}]`).removeClass('even').addClass('odd');
  }

  toggleOff() {
    return $(`table.order tr[data-address-id=${this.id}]`).removeClass('odd').addClass('even');
  }

};

$(function() {
  return $('table.order tr[data-address-id]').each(function(index, element) {
    return new OrderAddressRow($(element));
  });
});
