// Generated by CoffeeScript 2.7.0
var DependentSelect;

DependentSelect = class DependentSelect {
  constructor(el) {
    this.updateOptions = this.updateOptions.bind(this);
    this.el = el;
    this.dependingOn = $(`#${this.el.data("depending-on")}`);
    this.dependingOn.change(this.updateOptions);
    this.updateOptions();
  }

  updateOptions() {
    return this.el.find("option").each((index, element) => {
      var enabled, option;
      option = $(element);
      if (option.attr("value") === "") {
        return;
      }
      enabled = option.data("dependent-id").toString() === this.dependingOn.val();
      if (enabled) {
        return option.show();
      } else {
        option.prop("selected", false);
        return option.hide();
      }
    });
  }

};

$(function() {
  return $("select[data-depending-on]").each(function(index, element) {
    return new DependentSelect($(element));
  });
});
