// Generated by CoffeeScript 2.7.0
var MassEvent;

MassEvent = class MassEvent {
  constructor(el) {
    this.updateEvent = this.updateEvent.bind(this);
    this.toggleCheckboxes = this.toggleCheckboxes.bind(this);
    this.updateSubmitButton = this.updateSubmitButton.bind(this);
    this.submit = this.submit.bind(this);
    this.el = el;
    this.eventSelect = this.el.find("select[name=event]");
    this.eventSelect.change(this.updateEvent);
    this.checkBoxToggle = this.el.find("input#toggle-all");
    this.checkBoxToggle.prop("checked", false);
    this.checkBoxToggle.change(this.toggleCheckboxes);
    this.idCheckBoxes = this.el.find("input.id");
    this.idCheckBoxes.change(this.updateSubmitButton);
    this.submitButton = this.el.find("input[type=submit]");
    this.updateEvent();
    this.submitButton.click(this.submit);
  }

  updateEvent() {
    var selectedEvent;
    selectedEvent = this.eventSelect.val();
    this.el.find("tr[data-events]").each(function(index, element) {
      var events, row;
      row = $(element);
      events = row.data("events").split(" ");
      if (selectedEvent === "" || events.includes(selectedEvent)) {
        return row.show();
      } else {
        return row.hide();
      }
    });
    this.idCheckBoxes.prop("checked", false);
    this.checkBoxToggle.prop("checked", false);
    return this.updateSubmitButton();
  }

  toggleCheckboxes() {
    if (this.checkBoxToggle.is(":checked")) {
      this.idCheckBoxes.filter(":visible").prop("checked", true);
    } else {
      this.idCheckBoxes.prop("checked", false);
    }
    return this.updateSubmitButton();
  }

  updateSubmitButton() {
    if (this.idCheckBoxes.filter(":checked").length > 0 && this.eventSelect.val() !== "") {
      return this.submitButton.prop("disabled", false);
    } else {
      return this.submitButton.prop("disabled", true);
    }
  }

  submit(event) {
    event.preventDefault();
    const selectedOption = this.eventSelect.find("option:selected");
    let url = selectedOption.data("url") + "?";
    this.idCheckBoxes.filter(":checked").each(function(index, element) {
      var box;
      box = $(element);
      return url += `ids[]=${box.val()}&`;
    });
    this.el.prop("action", url);
    let method = "GET";
    if (selectedOption.data("method") == "POST") method = "POST";
    this.el.prop("method", method);
    return this.el.submit();
  }
};

$(function() {
  return $("form.mass-event").each(function(index, element) {
    return new MassEvent($(element));
  });
});
