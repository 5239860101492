// Generated by CoffeeScript 2.7.0
$(function() {
  return $("input[data-trainers]").click(function(event) {
    var input;
    input = $(this);
    $.ajax({
      type: "GET",
      url: input.data("url"),
      data: {
        trainer_ids: $("#" + input.data("trainers")).val()
      },
      dataType: "text",
      success: function(data, status, jqxhr) {
        return $("#" + input.data("target")).val(data);
      }
    });
    return event.preventDefault();
  });
});
