// Generated by CoffeeScript 2.7.0
var WeekLoader;

WeekLoader = class WeekLoader {
  constructor(el) {
    this.loadNextWeek = this.loadNextWeek.bind(this);
    this.checkScrollStatus = this.checkScrollStatus.bind(this);
    this.loadMoreWeeks = this.loadMoreWeeks.bind(this);
    this.el = el;
    this.spinner = $(".spinner");
    this.loadOnScrolling = false;
    this.scrollPosition = window.scrollY;
    this.scrolling = false;
    this.loadNextWeek();
    $(window).scroll(this.checkScrollStatus);
    $(window).on('resize', this.loadNextWeek);
    setInterval(this.loadMoreWeeks, 500);
  }

  loadNextWeek() {
    var url;
    this.spinner.show();
    url = this.el.find("[data-next-week-path]").last().data("next-week-path");
    return $.ajax({
      method: "GET",
      url: url,
      dataType: "html",
      success: (data, status, xhr) => {
        var newWeek;
        this.spinner.hide();
        newWeek = $(data);
        this.el.append(newWeek);
        newWeek.trigger("lihoma.weekAdded");
        if (this.isInViewPort(newWeek.filter('div').get(0))) {
          this.loadOnScrolling = false;
          return this.loadNextWeek();
        } else {
          return this.loadOnScrolling = true;
        }
      }
    });
  }

  checkScrollStatus() {
    var new_position;
    new_position = window.scrollY;
    if (new_position > this.scrollPosition) {
      this.scrolling = true;
    }
    return this.scrollPosition = new_position;
  }

  loadMoreWeeks() {
    if (this.loadOnScrolling && this.scrolling) {
      this.scrolling = false;
      this.loadNextWeek();
      return this.loadOnScrolling = false;
    }
  }

  isInViewPort(element) {
    var rect;
    rect = element.getBoundingClientRect();
    rect.bottom > 0 && rect.right > 0 && rect.left < (window.innerWidth || document.documentElement.clientWidth);
    return rect.top < (window.innerHeight || document.documentElement.clientHeight);
  }

};

$(function() {
  return $("#course_plan").each(function(index, element) {
    return new WeekLoader($(element));
  });
});
