// Generated by CoffeeScript 2.7.0
$(function() {
  return $("input#move_to_date").each((index, element) => {
    var input;
    input = $(element);
    return input.hide().datepicker({
      showOn: 'button',
      buttonImage: input.data("image"),
      buttonImageOnly: true,
      buttonText: "Datum wählen",
      defaultDate: input.val(),
      onClose: function(date) {
        if (date) {
          date = new Date(date);
          return window.location = input.data("url") + '?day=' + date.getDate() + '&month=' + (date.getMonth() + 1) + '&year=' + date.getFullYear();
        }
      }
    });
  });
});
