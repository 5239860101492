// Generated by CoffeeScript 2.7.0
var AddressCompanyField, move_to_tags, sort_tags;

AddressCompanyField = class AddressCompanyField {
  constructor(el) {
    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.el = el;
    this.select = $('select#address_organization_address_id');
    this.select.on('select2:unselect', this.enable);
    this.select.on('select2:select', this.disable);
  }

  enable() {
    return this.el.removeProp("disabled");
  }

  disable() {
    return this.el.prop("disabled", "disabled");
  }

};

$(document).ready(function() {
  $('textarea#address_company').each(function(index, element) {
    return new AddressCompanyField($(element));
  });
  return $("#get_tags_button").click(function() {
    var fieldset, form, setTagsUrl;
    // add tags available
    $.ajax($("#get_tags_button").data("tagsAvailableUrl")).done(function(html) {
      $(html).prependTo('#tags_available').addClass('odd');
      sort_tags('#tags_available');
      $('#tags_available').children().click(function() {
        return move_to_tags(this, '#tags_enabled');
      });
      $('<input id=new_tag_field name="tag" type="text"/>').insertAfter('#tags_available');
      $('<button id=create_tag_button type="button">hinzufügen</button>').insertAfter('#tags_available');
      $('<br/>').insertAfter('#tags_available');
      return $('#create_tag_button').click(function() {
        $('<span class="address_tag odd">' + $('#new_tag_field').val() + '</span>').prependTo('#tags_available');
        sort_tags('#tags_available');
        $('#tags_available').children().click(function() {
          return move_to_tags(this, '#tags_enabled');
        });
        return $('#new_tag_field').val('');
      });
    });
    // replace tags_enabled Div With Form
    setTagsUrl = $("#get_tags_button").data("setTagsUrl");
    form = $('<form method="post" action="' + setTagsUrl + '"></form>');
    $('#tags_enabled').replaceWith(form);
    fieldset = $("<fieldset></fieldset>").attr('id', 'tags_enabled').appendTo(form);
    $.ajax($("#get_tags_button").data("tagsEnabledUrl")).done(function(html) {
      $(html).prependTo('#tags_enabled');
      sort_tags('#tags_enabled');
      return $('#tags_enabled').children().click(function() {
        return move_to_tags(this, '#tags_available');
      });
    });
    $('<input id="save_tags_button" type="submit" name="commit" value="Tags speichern"/>').appendTo(form).click(function() {
      return $(form).submit(function(event) {
        var tags;
        tags = $('#tags_enabled').children("span").map(function() {
          return $(this).text();
        });
        console.log($.makeArray(tags));
        $.ajax({
          type: 'POST',
          url: $(form).attr('action'),
          dataType: 'html',
          data: {
            tags: $.makeArray(tags),
            tags_length: tags.length // tags_length is necessary, because if the tags array is empty, nothing is send at all. To be sure, that I do not accidentally delete all tags, I send the number of tags along
          },
          error: function() {
            console.log('AJAX post request failed');
            return false;
          }
        }).done(function(html) {
          $(form).replaceWith($('<div id="tags_enabled"></div'));
          $(html).appendTo('#tags_enabled');
          $('#tags_available').parent().empty().append($('<div id="tags_available"></div'));
          return $("#get_tags_button").show();
        })
        event.preventDefault();
        return false;
      });
    });
    return $("#get_tags_button").hide();
  });
});

sort_tags = function(selector) {
  var address_tag_spans;
  address_tag_spans = $(selector).children("span");
  $(selector).children("span").remove();
  address_tag_spans.sort(function(a, b) {
    if ($(a).text().toLowerCase() > $(b).text().toLowerCase()) {
      return 1;
    }
    if ($(a).text().toLowerCase() < $(b).text().toLowerCase()) {
      return -1;
    }
    return 0;
  });
  $(address_tag_spans).prependTo(selector);
  return $(selector).children("span").css('cursor', 'pointer').css("margin", "3px").hover(function() {
    return $(this).css("background-color", "rgb(220,220,220)");
  }, function() {
    return $(this).css("background-color", "");
  });
};

move_to_tags = function(tag, to) {
  var parent;
  parent = '#' + $(tag).parent().attr('id');
  $(tag).remove().appendTo(to).css("background-color", "");
  sort_tags(to);
  return $(to).children().click(function() {
    return move_to_tags(this, parent);
  });
};
