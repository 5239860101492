// Generated by CoffeeScript 2.7.0
var EditOptionedUntil;

EditOptionedUntil = class EditOptionedUntil {
  constructor(el) {
    var td;
    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.el = el;
    td = this.el.closest("td");
    this.dateDisplay = td.find(".optioned-until-display");
    this.formContainer = td.find('.optioned-until-form');
    this.el.click(this.showForm);
    this.formContainer.find("a").click(this.hideForm);
  }

  showForm(event) {
    event.preventDefault();
    this.formContainer.show();
    return this.dateDisplay.hide();
  }

  hideForm(event) {
    event.preventDefault();
    this.formContainer.hide();
    return this.dateDisplay.show();
  }

};

$(function() {
  return $('a.edit-optioned-until').each(function(index, element) {
    return new EditOptionedUntil($(element));
  });
});
