// Generated by CoffeeScript 2.7.0
var ActionDropdown;

ActionDropdown = class ActionDropdown {
  constructor(el) {
    this.scrollIfNeeded = this.scrollIfNeeded.bind(this);
    this.el = el;
    this.el.on('mouseenter', this.scrollIfNeeded);
  }

  scrollIfNeeded() {
    var dropdownBottom, dropdownHeight, jqWindow, windowBottom, windowScrollTop;
    jqWindow = $(window);
    windowScrollTop = jqWindow.scrollTop();
    dropdownHeight = this.el.find('ul').height();
    dropdownBottom = this.el.offset().top + this.el.height() + dropdownHeight;
    windowBottom = windowScrollTop + jqWindow.height();
    if (dropdownBottom > windowBottom) {
      return jqWindow.scrollTop(windowScrollTop + dropdownHeight);
    }
  }

};

$(function() {
  return $('.action-dropdown').each(function(index, element) {
    return new ActionDropdown($(element));
  });
});
