// Generated by CoffeeScript 2.7.0
var AdditionalOrderItem, AdditionalOrderItemAdder, CommentToggle, FutureDateSelect, HotelReservation, IDReplacer, Participation, Reservation, ReservationsForm,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

IDReplacer = class IDReplacer {
  constructor(el1) {
    this.replace = this.replace.bind(this);
    this.el = el1;
  }

  replace(attribute, newId = null, nested = false) {
    var className, elements;
    className = attribute.replace(/_/g, "-");
    elements = nested ? this.el.find(`.${className}`) : this.el;
    elements.each((index, element) => {
      var newElementId;
      element = $(element);
      newElementId = newId ? newId : new Date().getTime();
      this.replaceIn(element, attribute, newElementId);
      if (element.hasClass(className)) {
        return element.data("id", newElementId);
      }
    });
    return this;
  }

  replaceIn(container, attribute, newId) {
    container.find("input[name], textarea[name], select[name]").each(function(i, input) {
      var newIdProp, newName, oldIdProp, oldName;
      input = $(input);
      oldName = input.prop("name");
      newName = oldName.replace(new RegExp(`${attribute}s_attributes\\]\\[\\d+\\]`), `${attribute}s_attributes][${newId}]`);
      input.prop("name", newName);
      oldIdProp = input.prop("id");
      newIdProp = oldIdProp.replace(new RegExp(`${attribute}s_attributes_\\d+_`), `${attribute}s_attributes_${newId}_`);
      return input.prop("id", newIdProp);
    });
    return container.find("label[for]").each(function(i, label) {
      var newFor, oldFor;
      label = $(label);
      oldFor = label.prop("for");
      newFor = oldFor.replace(new RegExp(`${attribute}s_attributes_\\d+_`), `${attribute}s_attributes_${newId}_`);
      return label.prop("for", newFor);
    });
  }

};

FutureDateSelect = class FutureDateSelect {
  constructor(el1) {
    var minDate;
    this.el = el1;
    this.el.removeClass("hasDatepicker");
    minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    this.el.datepicker({
      minDate: minDate
    });
  }

};

CommentToggle = class CommentToggle {
  constructor(el1) {
    this.toggleComment = this.toggleComment.bind(this);
    this.el = el1;
    this.input = this.el.next(".comment-input");
    this.el.click(this.toggleComment);
  }

  toggleComment(event) {
    event.preventDefault();
    if (this.input.is(":visible")) {
      this.el.html("&#9654;&nbsp;Kommentar");
      return this.input.hide();
    } else {
      this.el.html("&#9660;&nbsp;Kommentar");
      return this.input.show();
    }
  }

};

Reservation = class Reservation {
  constructor(el1) {
    this.checkInitialConfirmation = this.checkInitialConfirmation.bind(this);
    this.fixConfirmation = this.fixConfirmation.bind(this);
    this.checkUpdatedConfirmation = this.checkUpdatedConfirmation.bind(this);
    this.el = el1;
    this.optionedUntil = this.el.find("input.future-date-select");
    new FutureDateSelect(this.optionedUntil);
    this.el.children("label.comment").each(function(index, element) {
      return new CommentToggle($(element));
    });
    this.optionedUntil.change(this.checkUpdatedConfirmation);
    this.checkInitialConfirmation();
  }

  checkInitialConfirmation(event) {
    var confirmInput;
    confirmInput = this.el.find("input.confirm");
    if ((this.optionedUntil.val() != null) && this.optionedUntil.val() !== "") {
      return this.fixConfirmation(confirmInput);
    }
  }

  fixConfirmation(confirmInput) {
    confirmInput.prev("input[type=hidden]").val("1").prop("disabled", false);
    confirmInput.prop("checked", true).change();
    return confirmInput.prop("disabled", true);
  }

  checkUpdatedConfirmation(event) {
    var confirmInput;
    confirmInput = this.el.find("input.confirm");
    if ((this.optionedUntil.val() != null) && this.optionedUntil.val() !== "") {
      return this.fixConfirmation(confirmInput);
    } else {
      confirmInput.prop("disabled", false);
      return confirmInput.prev("input[type=hidden]").val("0");
    }
  }

};

Participation = class Participation extends Reservation {
  static copy(times = 1) {
    const results = [];
    for (let i = 1; i <= times; i++) {
      results.push(setTimeout(function() {
        var copiedSelects, copy, last, lastAddressAutocomplete, lastSelects;
        last = $('.participation').last();
        lastAddressAutocomplete = last.find('select.address-autocomplete');
        lastAddressAutocomplete.select2('destroy');
        copy = last.clone();
        new IDReplacer(copy).replace("participation").replace("hotel_reservation", null, true);
        window.initAddressAutocomplete(lastAddressAutocomplete);
        copy.find('.errorExplanation').remove();
        last.parent().append(copy);
        copy.find('select.address-autocomplete').each(function(index, element) {
          var addressAutocomplete;
          addressAutocomplete = $(element);
          window.initAddressAutocomplete(addressAutocomplete);
          return addressAutocomplete.val(null).trigger('change');
        });
        copy.trigger("reservations.insert");
        copy.find('.add-additional-order-item').each(function(index, element) {
          return new AdditionalOrderItemAdder($(element));
        });
        lastSelects = last.find("select:not(.address-autocomplete)");
        copiedSelects = copy.find("select:not(.address-autocomplete)");
        lastSelects.each(function(index, select) {
          return copiedSelects.eq(index).val($(select).val());
        });
        return new Participation(copy);
      }, times));
    }
    return results;
  }

  constructor(el) {
    super(el);
    this.remove = this.remove.bind(this);
    this.el.find('input.orderer').prop('checked', false);
    this.el.find('.remove-participation').click(this.remove);
    this.el.find(".hotel-reservation").each(function(index, element) {
      return new HotelReservation($(element));
    });
  }

  remove(event) {
    boundMethodCheck(this, Participation);
    event.preventDefault();
    if ($('.participation').length > 1) {
      return this.el.remove();
    }
  }

};

HotelReservation = class HotelReservation extends Reservation {
  static add(link) {
    var container, element;
    container = link.closest(".participation .hotel-reservations");
    if (container.length === 0) {
      container = $(".reservations");
    }
    element = $(link.data("template"));
    new IDReplacer(element).replace("hotel_reservation");
    container.append(element);
    return element.each(function(index, reservation) {
      var hotelReservation, participation;
      reservation = $(reservation);
      reservation.find('select.address-autocomplete').each(function(index, element) {
        var addressAutocomplete;
        addressAutocomplete = $(element);
        return window.initAddressAutocomplete(addressAutocomplete);
      });
      hotelReservation = new HotelReservation(reservation);
      if (container.hasClass("hotel-reservations")) {
        participation = container.closest(".participation");
        return hotelReservation.initFrom(participation);
      } else {
        return $(reservation).trigger("reservations.insert");
      }
    });
  }

  static copy(times = 1) {
    const results = [];
    for (let i = 1; i <= times; i++) {
      results.push(setTimeout(function() {
        var className, copy, k, last, lastAddressAutocomplete, len, ref1, selector;
        last = $('.reservations > .hotel-reservation').last();
        lastAddressAutocomplete = last.find('select.address-autocomplete');
        lastAddressAutocomplete.select2('destroy');
        copy = last.clone();
        new IDReplacer(copy).replace("hotel_reservation");
        window.initAddressAutocomplete(lastAddressAutocomplete);
        copy.find('.errorExplanation').remove();
        last.parent().append(copy);
        copy.find('select.address-autocomplete').each(function(index, element) {
          var addressAutocomplete;
          addressAutocomplete = $(element);
          window.initAddressAutocomplete(addressAutocomplete);
          return addressAutocomplete.val(null).trigger('change');
        });
        ref1 = ["product-id", "hotel-address-id"];
        for (k = 0, len = ref1.length; k < len; k++) {
          className = ref1[k];
          selector = `select.${className}`;
          copy.find(selector).val(last.find(selector).val());
        }
        copy.trigger("reservations.insert");
        return new HotelReservation(copy);
      }, times));
    }
    return results;
  }

  constructor(el) {
    super(el);
    this.setOptionedUntil = this.setOptionedUntil.bind(this);
    this.remove = this.remove.bind(this);
    this.initFrom = this.initFrom.bind(this);
    this.el.find("input.date-select").removeClass("hasDatepicker").datepicker();
    this.el.find('.remove-hotel-reservation').click(this.remove);
  }

  setOptionedUntil(value) {
    boundMethodCheck(this, HotelReservation);
    return this.optionedUntil.val(value);
  }

  remove(event) {
    boundMethodCheck(this, HotelReservation);
    event.preventDefault();
    return this.el.remove();
  }

  initFrom(participation) {
    var participationConfirm, participationOption, selfConfirm;
    boundMethodCheck(this, HotelReservation);
    if (participation.data("id")) {
      new IDReplacer(this.el).replace("participation", participation.data("id"));
    }
    participationOption = participation.find("> input.optioned-until");
    this.el.find("input.optioned-until").val(participationOption.val());
    participationConfirm = participation.find("> input.confirm");
    selfConfirm = this.el.find("input.confirm");
    selfConfirm.prop("checked", participationConfirm.prop("checked"));
    selfConfirm.prop("disabled", participationConfirm.prop("disabled"));
    if (participation.find("> input.rest-seat").is(":checked")) {
      this.el.find("option[data-special=rest_seat_accommodation]").prop("selected", true);
    }
    return this.checkInitialConfirmation();
  }

};

AdditionalOrderItem = class AdditionalOrderItem {
  constructor(el1) {
    this.remove = this.remove.bind(this);
    this.el = el1;
    this.el.find(".remove-order-item").click(this.remove);
  }

  remove(event) {
    event.preventDefault();
    return this.el.remove();
  }

};

AdditionalOrderItemAdder = class AdditionalOrderItemAdder {
  constructor(el1) {
    this.showDialog = this.showDialog.bind(this);
    this.cleanupDialog = this.cleanupDialog.bind(this);
    this.addOrderItem = this.addOrderItem.bind(this);
    this.el = el1;
    this.dialog = $('.new-course-date-order-item-dialog');
    this.form = this.dialog.find('form');
    this.el.click(this.showDialog);
  }

  showDialog(event) {
    event.preventDefault();
    this.dialog.dialog('open');
    return this.form.on('submit', this.addOrderItem);
  }

  cleanupDialog() {
    this.form.off('submit');
    this.form[0].reset();
    return this.dialog.dialog('close');
  }

  addOrderItem(event) {
    var date, productId, productName, productSelection, quantity, template;
    event.preventDefault();
    quantity = this.form.find('input[name="course_dates_order_item[quantity]"]').val();
    productSelection = this.form.find('select option:selected');
    productId = productSelection.attr('value');
    productName = productSelection.text();
    template = $(this.el.data('template'));
    date = new Date().getTime();
    template.find('input.product-id').attr('value', productId);
    template.find('input.quantity').attr('value', quantity);
    template.find('span.quantity').text(quantity);
    template.find('.product-name').text(productName);
    new IDReplacer(template).replace("additional_order_item");
    this.el.closest('.additional-order-items').find("ul").append(template);
    new AdditionalOrderItem(template);
    return this.cleanupDialog();
  }

};

ReservationsForm = class ReservationsForm {
  constructor(el1) {
    this.addNewOrderField = this.addNewOrderField.bind(this);
    this.addOrderId = this.addOrderId.bind(this);
    this.addHotelReservation = this.addHotelReservation.bind(this);
    this.toggleOrdererCheckbox = this.toggleOrdererCheckbox.bind(this);
    this.toggleOrdererSelect = this.toggleOrdererSelect.bind(this);
    this.toggleOddEven = this.toggleOddEven.bind(this);
    this.el = el1;
    this.ordererSelect = this.el.find(".orderer-address select");
    this.ordererSelect.change(this.toggleOrdererSelect);
    this.el.find('input[type=submit].new-order').click(this.addNewOrderField);
    this.addButtons = this.el.find('input[type=submit].add-to-order');
    this.addButtons.click(this.addOrderId);
    this.el.find(".reservations .participation").each(function(index, element) {
      return new Participation($(element));
    });
    this.el.find(".reservations .hotel-reservation").each(function(index, element) {
      return new HotelReservation($(element));
    });
    this.el.find('.add-participation').click(function(event) {
      var link;
      event.preventDefault();
      link = $(event.target);
      return Participation.copy(link.data("count"));
    });
    this.el.on('click', '.add-hotel-reservation', this.addHotelReservation);
    this.el.on("change", "input.orderer", this.toggleOrdererCheckbox);
    this.el.on("reservations.insert", this.toggleOddEven);
  }

  addNewOrderField(event) {
    return this.el.append('<input type="hidden" name="new_order" value="1">');
  }

  addOrderId(event) {
    var orderId;
    orderId = $(event.target).data("order-id");
    return this.el.append(`<input type=\"hidden\" name=\"reservations_creation[order_id]\" value=\"${orderId}\">`);
  }

  addHotelReservation(event) {
    var container, existingReservations, link;
    event.preventDefault();
    link = $(event.target);
    container = link.closest(".participation .hotel-reservations");
    existingReservations = this.el.find(".reservations > .hotel-reservation");
    if (container.length === 0 && existingReservations.length > 0) {
      return HotelReservation.copy();
    } else {
      return HotelReservation.add($(event.target));
    }
  }

  toggleOrdererCheckbox(event) {
    var checkBox;
    checkBox = $(event.target);
    if (checkBox.prop('checked')) {
      this.ordererSelect.prop('disabled', true);
      this.el.find('input.orderer').not(checkBox).prop('checked', false);
      return this.el.find('input[type=submit].add-to-order').prop('disabled', true);
    } else {
      this.ordererSelect.prop('disabled', false);
      return $('input[type=submit].add-to-order').prop('disabled', false);
    }
  }

  toggleOrdererSelect(event) {
    var select;
    select = $(event.target);
    // confirmSet = $("input.confirm:checked").length > 0
    // return if confirmSet
    if (select.val() !== "") {
      this.el.find("input.orderer").prop("disabled", true);
      return this.addButtons.prop('disabled', true);
    } else {
      this.el.find("input.orderer").prop("disabled", false);
      return this.addButtons.prop('disabled', false);
    }
  }

  toggleOddEven(event) {
    var newReservation, previousReservation;
    newReservation = $(event.target);
    previousReservation = newReservation.prev(".participation, .hotel-reservation");
    if (previousReservation.hasClass("odd")) {
      return newReservation.removeClass("odd").addClass("even");
    } else {
      return newReservation.removeClass("even").addClass("odd");
    }
  }

};

$(function() {
  $("form.reservations-form").each(function(index, element) {
    return new ReservationsForm($(element));
  });
  $('.new-course-date-order-item-dialog').dialog({
    autoOpen: false,
    width: 500
  }).find("select").select2({
    width: 450
  });
  $(".additional-order-item").each(function(index, element) {
    return new AdditionalOrderItem($(element));
  });
  return $('.add-additional-order-item').each(function(index, element) {
    return new AdditionalOrderItemAdder($(element));
  });
});
