// Generated by CoffeeScript 2.7.0
var AdressDialog;

AdressDialog = class AdressDialog {
  constructor(el) {
    this.selectAddress = this.selectAddress.bind(this);
    this.open = this.open.bind(this);
    this.addressInvalid = this.addressInvalid.bind(this);
    this.addressUpdated = this.addressUpdated.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.el = el;
    this.el.dialog({
      autoOpen: false,
      width: 800
    });
    $("body").delegate("form#new_address", "ajax:success", this.addressUpdated);
    $("body").delegate("form#new_address", "ajax:error", this.addressInvalid);
    $("body").delegate("a[data-address-form]", "click", this.open);
    $("body").delegate("a.select-address", "click", this.selectAddress);
  }

  selectAddress(event) {
    var link;
    link = $(event.target);
    this.setAddress(link.data("address-id"), link.data("address-search-string"));
    return event.preventDefault();
  }

  open(event) {
    this.el.dialog('open');
    this.el.data("caller", $(event.target));
    return event.preventDefault();
  }

  addressInvalid(event, response) {
    if (response.status === 409) {
      event.target.reset();
      this.el.find("input[type=submit]").removeAttr("disabled");
      this.el.data("form_html", this.el.html());
      return this.el.html(response.responseText);
    } else {
      return alert(response.responseText);
    }
  }

  addressUpdated(event, data) {
    var address;
    event.target.reset();
    address = data["address"];
    return this.setAddress(address["id"], address["search_string"]);
  }

  setAddress(id, search_string) {
    var address_field, calling_link;
    if (this.el.data("form_html")) {
      this.el.html(this.el.data("form_html"));
    }
    this.el.dialog('close');
    calling_link = this.el.data("caller");
    address_field = calling_link.parent(".autocomplete_container").find("select.address-autocomplete");
    address_field.append(`<option value=\"${id}\">${search_string}</option>`);
    address_field.val(id);
    return address_field.trigger('change');
  }

};

$(function() {
  return $("#address_form_container").each(function(index, element) {
    return new AdressDialog($(element));
  });
});
