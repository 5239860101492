import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="field-value"
export default class extends Controller {
  static targets = ["field"]
  static values = {key: String}

  connect() {
  }

  update(event) {
    event.preventDefault()
    this.fieldTarget.value = event.params.newValue 
  }
}
