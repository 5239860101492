// Generated by CoffeeScript 2.7.0
var FieldToggle;

FieldToggle = class FieldToggle {
  constructor(el) {
    this.handleChange = this.handleChange.bind(this);
    this.update = this.update.bind(this);
    this.el = el;
    this.fieldName = this.el.data("field-toggle");
    this.el.find(`input[name=\"${this.fieldName}\"]`).each((index, element) => {
      element = $(element);
      if (element.is(":checked")) {
        this.update(element);
      }
      return element.change(this.handleChange);
    });
  }

  handleChange(event) {
    return this.update($(event.target));
  }

  update(input) {
    var matchingFields, toggleableFields;
    toggleableFields = this.el.find(`[data-field-toggle-name=\"${this.fieldName}\"][data-field-toggle-value]`);
    toggleableFields.removeClass("highlight").addClass("hide");
    matchingFields = toggleableFields.filter(`[data-field-toggle-value~=\"${input.val()}\"]`);
    return matchingFields.removeClass("hide").addClass("highlight");
  }

};

$(function() {
  return $('[data-field-toggle]').each(function(index, element) {
    return new FieldToggle($(element));
  });
});
