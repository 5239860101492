import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-part"
export default class extends Controller {
  static targets = ["switch", "toggleable"]

  connect() {
    this.toggleInputs(this.switchTarget.checked)
  }

  toggle(event) {
    this.toggleInputs(event.target.checked)
  }

  toggleInputs(disable) {
    this.toggleableTarget.querySelectorAll("input").forEach((i) => i.disabled = disable)
  }
}
