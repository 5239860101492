// Generated by CoffeeScript 2.7.0
$(function() {
  $('a[data-show]').one('click', function(event) {
    var link, rows;
    link = $(this);
    rows = $(`tr.split-payment[data-payment-id=${link.data('show')}]`);
    rows.removeClass('hide');
    return rows.find('input, textarea, select').each(function(index, element) {
      return $(element).removeAttr('disabled');
    });
  });
  return $('a[data-destroy=payment]').on('ajax:success', function() {
    var link, possibleInvoices, row, splitPayments;
    link = $(this);
    row = link.closest('tr');
    splitPayments = row.nextUntil('.payment', '.split-payment');
    possibleInvoices = row.nextUntil('.payment', '.possible-invoices, .possible-invoice');
    row.remove();
    splitPayments.remove();
    return possibleInvoices.remove();
  });
});
