// Generated by CoffeeScript 2.7.0
var CheckColumn;

CheckColumn = class CheckColumn {
  constructor(el) {
    this.checkColumn = this.checkColumn.bind(this);
    this.el = el;
    this.el.click(this.checkColumn);
    this.column = this.el.data("column");
  }

  checkColumn(event) {
    event.preventDefault();
    return this.el.closest("table").find(`tbody td:nth-child(${this.column}) input:enabled`).prop("checked", true);
  }

};

$(function() {
  return $(".check-column").each(function(index, element) {
    return new CheckColumn($(element));
  });
});
