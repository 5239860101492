// Generated by CoffeeScript 2.7.0
$(function() {
  $('.hotel-reservation.earlier-arrival').click(function(e) {
    var hr;
    hr = $(this);
    if (e.offsetX <= parseInt(hr.css('borderLeftWidth'))) {
      return window.location = $('a#previous-week-link').attr('href');
    }
  });
  $('.hotel-reservation.later-departure').click(function(e) {
    var hr;
    hr = $(this);
    if (e.offsetX > hr.outerWidth() - parseInt(hr.css('borderRightWidth'))) {
      return window.location = $('a#next-week-link').attr('href');
    }
  });
  return $('select.room').select2({
    templateResult: function(option) {
      if (!option.id) {
        return option.text;
      }
      return $($(option.element).data('html'));
    }
  });
});
