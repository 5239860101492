// Generated by CoffeeScript 2.7.0
var insert_nested_form;

insert_nested_form = function(button, button_class, container_class, form_html, level = 1) {
  var container, form_dom, index, parent_id, participations, timestamp;
  index = $(button_class).index(button);
  if (level === 2) {
    parent_id = button.parent().find('input').attr('name').match(/.*?\[(\d+)\]/)[1];
    form_html = form_html.replace(/(attributes[_\]\[]+)\d+/g, "$1" + parent_id);
  }
  form_dom = $(form_html);
  timestamp = (new Date()).getTime().toString();
  participations = form_dom.find("fieldset.participation");
  if (participations.length > 0) {
    participations.each(function(index, participation) {
      participation = $(participation);
      return participation.html(participation.html().replace(/NEW_RECORD/g, timestamp + index));
    });
  } else {
    form_dom.each(function(index, element) {
      element = $(element);
      if (element.html()) {
        return element.html(element.html().replace(/NEW_RECORD/g, timestamp));
      }
    });
  }
  container = $(container_class).eq(index);
  container.append(form_dom);
  container.trigger('nested-form-inserted', [form_dom]);
  return false;
};

$(function() {
  return $('body').delegate('.insert-nested-form', 'click', function(event) {
    var button, level;
    button = $(this);
    level = button.data('level') || 1;
    insert_nested_form(button, button.data('button-class'), button.data('container-class'), button.data('template'), level);
    return event.preventDefault();
  });
});
