import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dependent-dates"
export default class extends Controller {
  static targets = ["baseInput", "dependentInput"]
  static values = {
    sourceSelector: String,
    sourceAttribute: String
  }

  connect() {
    this.update()
  }

  update() {
    const source = document.querySelector(this.sourceSelectorValue)
    const numberOfDays = parseInt(source.dataset[this.sourceAttributeValue])
    this.updateInput(numberOfDays)
  }

  updateInput(numberOfDays) {
    if (this.baseInputTarget.value == "") return
    const baseDate = new Date(this.baseInputTarget.value)
    const dependentDate = new Date(baseDate.getTime())
    dependentDate.setDate(dependentDate.getDate() + numberOfDays - 1)
    const dateString = dependentDate.toISOString().replace(/T.*/, '')
    this.dependentInputTarget.value = dateString
  }
}
